<template>
  <div class="homes">
    <el-row :gutter="20">
      <el-col :span="23">
        <!-- 查询 -->
        <el-form
          :inline="true"
          class="demo-form-inline"
          style="margin-left: 6px; height: 53px"
        >
          <el-form-item label="是否主要：">
            <el-select v-model="params.isMajor" placeholder="请选择是否主要">
              <el-option
                v-for="item in newsInfoTypeMap"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <!--          <el-form-item label="新闻标题：">-->
          <!--            <el-input-->
          <!--              v-model="pageInfo.wrapper.title"-->
          <!--              placeholder="请输入新闻标题"-->
          <!--            ></el-input>-->
          <!--          </el-form-item>-->
          <el-form-item>
            <el-button type="primary" icon="el-icon-search" @click="query()"
              >查询</el-button
            >
          </el-form-item>
          <el-form-item>
            <el-button type="primary" icon="el-icon-refresh" @click="reset()"
              >重置</el-button
            >
          </el-form-item>
          <el-form-item>
            <el-button type="primary" icon="el-icon-plus" @click="userAddData"
              >新增</el-button
            >
          </el-form-item>
        </el-form>
      </el-col>
    </el-row>
    <el-row>
      <!-- 列表 -->
      <el-col :span="24"
        ><div class="grid-content bg-purple">
          <el-table
            :data="this.tableData"
            border
            max-height="580"
            style="width: 100%"
          >
            <el-table-column
              type="index"
              prop="sortOrder"
              header-align="center"
              align="center"
              label="序号"
              width="60"
              :index="indexMethod"
            >
            </el-table-column>
            <el-table-column
              prop="name"
              label="产品名称"
              header-align="center"
            >
            </el-table-column>
            <el-table-column
              prop="isMajor"
              label="是否主要"
              header-align="center"
              align="center"
              width="150"
            >
            </el-table-column>
            <el-table-column
              prop="createTime"
              header-align="center"
              align="center"
              label="创建时间"
              width="180"
            >
            </el-table-column>
            <el-table-column
              label="操作"
              header-align="center"
              align="center"
              width="200"
            >
              <template slot-scope="scope">
                <el-button
                  size="mini"
                  type="primary"
                  icon="el-icon-edit"
                  @click="handleEdit(scope.row.id)"
                  >编辑</el-button
                >
                <el-button
                  size="mini"
                  type="danger"
                  icon="el-icon-delete"
                  @click="handleDelete(scope.row.id)"
                  >删除</el-button
                >
              </template>
            </el-table-column>
          </el-table>
        </div></el-col>
    </el-row>
    <div style="margin-top: 10px; height: 3px; width: 100%"></div>
    <!-- 表单 -->
    <el-dialog
      :title="this.title"
      :visible.sync="dialogFormVisible"
      :append-to-body="true"
      top="5vh"
      width="800px"
      @close="close('formName')"
    >
      <el-form style="width: 100%" :model="form" :rules="rules" ref="formName">
        <el-form-item label="产品名称：" class="form" prop="name">
          <el-input
            v-model="form.name"
            placeholder="请输入产品名称"
            class="formInput"
          ></el-input>
        </el-form-item>
        <el-form-item label="是否主要：" class="form" prop="isMajor">
          <el-select
            v-model="form.isMajor"
            placeholder="请选择是否主要"
            class="formInput"
          >
            <el-option
              v-for="item in newsInfoTypeMap"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="简短描述：" prop="shortDesc" class="form1">
          <el-input
            type="textarea"
            :rows="2"
            resize="none"
            v-model="form.shortDesc"
            placeholder="请输入简短描述"
            class="formInput"
            style="width: 79.4%"
          ></el-input>
        </el-form-item>
        <el-form-item label="内容介绍：" class="form error" prop="content">
          <vue-editor
            v-model="form.content"
            style="width: 172.2%;
              height:150px;
              margin-left: 93px;
              margin-top: 1px;"
            :editor-toolbar="customToolbar"
            class="formInput"
          ></vue-editor>
        </el-form-item>
        <el-form-item label="产品图片：" class="form1" prop="picList">

          <el-upload
            action=""
            ref="upload"
            list-type="picture-card"
            :on-preview="handlePictureCardPreview"
            :on-remove="handleRemove"
            clearFiles
            class="formInput"
            style="margin-left: 92px; width: 79.8%;"
            :file-list="form.picList"
            :limit="20"
            :before-upload="imgUpload"
            :on-change="changeIamgeList"
          >

            <i class="el-icon-plus"></i>
          </el-upload>
          <el-dialog :visible.sync="dialogVisible" :append-to-body="true">
            <img width="100%" :src="dialogImageUrl" alt="" />
          </el-dialog>
        </el-form-item>
        <div class="faherd">
          <el-form-item
            v-for="(item, index) in form.picList"
            :key="index"
            label="描述："
            class="form"
            :prop="item.picDesc"
          >
            <el-input
              type="textarea"
              :rows="4"
              v-model="item.picDesc"
              placeholder="请输入描述"
              class="formInput textarea-div"
            ></el-input>
          </el-form-item>
          <div class="faherdTitle">
            <el-form-item
              v-for="(item, index) in form.picList"
              :key="index"
              label="标题："
              class="form"
              :prop="item.picTitle"
            >
              <el-input
                v-model="item.picTitle"
                placeholder="请输入标题"
                class="formInput"
              ></el-input>
            </el-form-item>
          </div>
        </div>

        <el-form-item
          style="display: flex;flex-direction: row;justify-content: flex-end;margin: 10px 0;"
        >
          <el-button type="primary" @click="formSave('formName')"
            >确 定</el-button
          >
          <el-button @click="formCancel('formName')">取 消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>
<script>
import { VueEditor } from 'vue2-editor'
import {
  uploadFile,
  webServerHttpUrlString,
} from '@/api/industryInformation'
import { productInsertForIdSave, productQueryList, productDetail, productUpdate,productDelete } from "../../api/otherPRoductsAdmin";
export default {
  name: 'otherProductsAdmin',
  components: { VueEditor },
  data() {
    return {
      customToolbar: [
        // 富文本框配置文件
        ['bold', 'italic', 'color', 'background', 'underline'],
        [{ list: 'ordered' }, { list: 'bullet' }],
        ['image', 'video', 'code-block', 'code', 'link'],
        ['clean']
      ],
      title: null, // 表单弹窗标题
      tableData: [], // 列表数据
      dialogFormVisible: false, // 表单弹框开关
      recordsTotal: null, // 数据总数
      // page: 1,
      pageSize: 5,
      // pageInfo: {
      //   // 分页查询数据
      //   page: 1,
      //   size: 10,
      //   params: {
      //     // 搜索数据
      //     title: null,
      //   }
      // },
      // pageInfo: {
      //   page: {
      //     current: 1,
      //     size: 10
      //   },
      params: {
        isMajor: null
      },
      current: 1,
      size: 10,
      // wrapper: {
      //   title: null
      // },
      // },
      folder: {
        folder: 'messageInfo'
      },
      form: {
        // 表单数据
        content: null,
        id: null,
        // picPath: null,
        shortDesc: null,
        // picDesc: null,
        isMajor:null,
        name:null,
        picList:[]
      },
      newsInfoTypeMap: [
        {
          id:'Y',
          name:'是'
        },
        {
          id:'N',
          name:'否'
        }
      ], // 新闻类型集合
      web: null, // 图片路径
      picList: [], // 图片数据
      dialogImageUrl: '', // 图片显示的路径
      dialogVisible: false, // 查看文件的弹窗开关
      rules: {
        // 表单校验
        name: [{ required: true, message: '请输入产品名称', trigger: 'blur ' }],
        shortDesc: [
          { required: true, message: '请输入简短描述', trigger: 'blur' }
        ],
        isMajor: [
          { required: true, message: '请选择是否主要', trigger: 'blur' }
        ],
        picList: [
          { required: true, message: '请选择产品图片', trigger: 'blur' }
        ],
        picDesc: [
          { required: true, message: '请选择产品描述', trigger: 'blur' }
        ],
        content: [
          { required: true, message: '请输入产品内容', trigger: 'blur' }
        ]
      }
    }
  },
  created() {
    this.getList()
    webServerHttpUrlString().then(res => {
      this.web = res
    })
  },
  mounted() {},
  methods: {
    // 文件上传时钩子
    imgUpload(file) {
      // this.form.picList = []
      console.log(file)
      let formdata = new FormData()
      formdata.append('folder', 'otherProducts')
      formdata.append('file', file)

      uploadFile(formdata).then(res => {
        console.log('res', res)
        // this.dialogImageUrl = this.web + res;
        console.log('this.web',this.web)
        this.form.picList.push({
          url: this.web + res,
          picDesc: null,
          picTitle: null
        })
        console.log('上传前this.form.picList', this.form.picList)
        // this.form.picPath = res
      })
    },
    changeIamgeList(file, fileList) {
      console.log('文件变化时---file', file)
      console.log('文件变化时---fileList', fileList)
    },
    // 文件列表移除文件时的钩子
    handleRemove(file, fileList) {
      // this.form.picPath = null
      for (let len = this.form.picList.length, i = len - 1; i >= 0; i--) {
        console.log('this.form.picList[i].url',this.form.picList[i].url)
        console.log('file.url',file.url)
        if (this.form.picList[i].url === file.url) {
          this.form.picList.splice(i, 1)
          console.log('123删除')
        }
      }
      console.log('this.form.picList', this.form.picList)
      console.log('删除时------file', file)
      console.log('删除时-------fileList', fileList)
    },
    // 点击文件列表中已上传的文件时的钩子
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url
      this.dialogVisible = true
    },
    // 图片上传返回的数据
    // handleAvatarSuccess(response) {
    //   this.form.picPath = response;
    // },
    // 弹框关闭事件
    close(formName) {
      this.$refs[formName].resetFields()
      this.$refs.upload.clearFiles()
    },
    // 序号
    indexMethod(index) {
      index = index + 1 + (this.current - 1) * this.size
      return index
    },
    //清空表单
    formData() {
      this.form.shortDesc = null
      this.form.content = null
      this.form.picList = []
      this.form.id = null
      this.form.name = null
      this.form.isMajor = null
    },
    //格式化类型
    // codeName(row) {
    //   if (this.tableData != null) {
    //     for (let i = 0; i < this.newsInfoTypeMap.length; i++) {
    //       if (row.typeId === this.newsInfoTypeMap[i].id) {
    //         return this.newsInfoTypeMap[i].name;
    //       }
    //     }
    //   }
    // },
    // 获取列表内容
    getList() {
      productQueryList().then(res => {
        this.tableData = res
      })
    },
    // 查询
    query() {
      this.getList()
    },
    // 重置
    reset() {
      this.params.isMajor = null
      this.getList()
    },
    //保存按钮
    formSave(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          this.$confirm('您确定保存吗？', '信息提示', {
            cancelButtonClass: 'btn-custom-cancel',
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          })
            .then(() => {
              console.log('保存前的this.form.picList', this.form.picList)
              if (this.form.id == null) {
                productInsertForIdSave(this.form).then(res => {
                  console.log(res)
                  this.$alert('保存成功！', '信息提示', {
                    confirmButtonText: '确定',
                    type: 'success'
                  }).then(() => {
                    this.getList()
                    this.formData()
                    this.dialogFormVisible = false
                    this.$refs[formName].resetFields()
                  })
                })
              } else {
                productUpdate(this.form).then(res => {
                  console.log(res,'123132')
                  this.$alert('保存成功！', '信息提示', {
                    confirmButtonText: '确定',
                    type: 'success'
                  }).then(() => {
                    this.getList()
                    this.formData()
                    this.dialogFormVisible = false
                    this.$refs[formName].resetFields()
                  })
                })
              }
            })
            .catch(() => {})
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    //新增
    userAddData() {
      this.form.picList = [];
      this.title = '新增'
      this.formData()
      this.dialogFormVisible = true
    },
    //编辑
    async handleEdit(id) {
      this.form.picList = []
      this.title = '编辑'
      await productDetail(id).then(res => {
        this.form = res
        this.form.id = res.id
        // this.form.push({
        //   url: res.picPathHttpUrl
        // })

      })
      this.$nextTick(() => {
        this.dialogFormVisible = true
      })
    },
    //删除
    handleDelete(id) {
      this.$confirm('您确定删除吗？', '信息提示', {
        cancelButtonClass: 'btn-custom-cancel',
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          productDelete(id).then(res => {
            console.log(res)
            this.$alert('删除成功！', '信息提示', {
              confirmButtonText: '确定',
              type: 'success'
            }).then(() => {
              this.getList()
            })
          })
        })
        .catch(() => {})
    },
    // 取消
    formCancel(formName) {
      this.dialogFormVisible = false
      this.$refs[formName].resetFields()
    },

  }
}
</script>
<style lang="less" scoped>
.homes {
  margin: auto;
}
.form {
  width: 46%;
  display: inline-block;
  margin: 10px 10px;
}
.form1 {
  width: 100%;
  display: inline-block;
  margin: 10px 10px;
}
.formInput {
  width: 67%;
}
/deep/.el-dialog__body {
  margin-top: -19px;
  margin-left: 15px;
}
/deep/.ql-editor {
  min-height: 100px !important;
}

/deep/.el-upload--picture-card {
  background-color: #fbfdff;
  border: 1px dashed #c0ccda;
  border-radius: 6px;
  box-sizing: border-box;
  width: 148px;
  height: 148px;
  line-height: 80px;
  vertical-align: top;
}
/deep/.el-upload--picture-card {
  width: 70px;
  height: 70px;
  margin-top: 40px;
}

/deep/.ql-container {
  height: 50%;
}
/deep/.el-dialog__body {
  margin-left: 15px;
}
/deep/.el-form-item__error {
  margin-left: 95px;
}
.error/deep/.el-form-item__error {
  margin-top: 0;
}
/deep/ #quill-container {
  height: 100px;
}
/deep/ [data-v-0dd82d4c] .ql-editor {
  min-height: 100px !important;
}
/deep/.quillWrapper .ql-snow.ql-toolbar {
  padding-bottom: 0px;
}
/*去除upload组件过渡效果*/
/deep/ .el-upload-list__item {
  transition: none !important;
}
/deep/.el-upload-list--picture-card .el-upload-list__item{
  display: block;
}
.faherd /deep/.el-form-item:nth-child(1){
  width: 520px;
  position: absolute;
  top: 475px;     top: 430px;
  display: block;
  left: 300px;
}
.faherd /deep/.el-form-item:nth-child(2){
  width: 520px;

  position: absolute;
  top: 582px;
  display: block;
  left: 300px;
}
.faherd /deep/.el-form-item:nth-child(3){
  width: 520px;

  position: absolute;
  top: 740px;
  display: block;
  left: 300px;
}
.faherd /deep/.el-form-item:nth-child(4){
  width: 520px;

  position: absolute;
  top: 892px;
  display: block;
  left: 300px;
}
.faherd /deep/.el-form-item:nth-child(5){
  width: 520px;

  position: absolute;
  top: 1045px;
  display: block;
  left: 300px;
}
.faherd /deep/.el-form-item:nth-child(6){
  width: 520px;

  position: absolute;
  top: 1210px;
  display: block;
  left: 300px;
}
.faherd /deep/.el-form-item:nth-child(7){
  width: 520px;

  position: absolute;
  top: 1367px;
  display: block;
  left: 300px;
}
.faherd /deep/.el-form-item:nth-child(8){
  width: 520px;

  position: absolute;
  top: 1525px;
  display: block;
  left: 300px;
}
.faherd /deep/.el-form-item:nth-child(9){
  width: 520px;

  position: absolute;
  top: 1678px;
  display: block;
  left: 300px;
}
.faherd /deep/.el-form-item:nth-child(10){
  width: 520px;

  position: absolute;
  top: 1835px;
  display: block;
  left: 300px;
}
.faherd /deep/.el-form-item:nth-child(11){
  width: 520px;

  position: absolute;
  top: 1993px;
  display: block;
  left: 300px;
}
//title
.faherd .faherdTitle /deep/.el-form-item:nth-child(1){
  width: 545px;

  position: absolute;
  top: 375px;
  display: block;
  left: 300px;
}
.faherd .faherdTitle /deep/.el-form-item:nth-child(2){
  width: 545px;

  position: absolute;
  top: 530px;
  display: block;
  left: 300px;
}
.faherd .faherdTitle /deep/.el-form-item:nth-child(3){
  width: 545px;
  position: absolute;
  top: 684px;
  display: block;
  left: 300px;
}
.faherd .faherdTitle /deep/.el-form-item:nth-child(4){
  width: 545px;
  position: absolute;
  top: 840px;
  display: block;
  left: 300px;
}
.faherd .faherdTitle /deep/.el-form-item:nth-child(5){
  width: 545px;
  position: absolute;
  top: 991px;
  display: block;
  left: 300px;
}
.faherd .faherdTitle /deep/.el-form-item:nth-child(6){
  width: 545px;
  position: absolute;
  top: 1146px;
  display: block;
  left: 300px;
}
.faherd .faherdTitle /deep/.el-form-item:nth-child(7){
  width: 545px;
  position: absolute;
  top: 1313px;
  display: block;
  left: 300px;
}
.faherd .faherdTitle /deep/.el-form-item:nth-child(8){
  width: 545px;
  position: absolute;
  top: 1470px;
  display: block;
  left: 300px;
}
.faherd .faherdTitle /deep/.el-form-item:nth-child(9){
  width: 545px;
  position: absolute;
  top: 1630px;
  display: block;
  left: 300px;
}
.faherd .faherdTitle /deep/.el-form-item:nth-child(10){
  width: 545px;
  position: absolute;
  top: 1782px;
  display: block;
  left: 300px;
}
.faherd .faherdTitle /deep/.el-form-item:nth-child(11){
  width: 545px;
  position: absolute;
  top: 1937px;
  display: block;
  left: 300px;
}
/deep/ .el-textarea__inner{
  line-height: 1.2 !important;
}
.textarea-div{
  width: 70% !important;
}
</style>
